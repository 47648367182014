<template>
  <div @click="toBlogDetails($event)" class="col-xl-4 col-md-6 col-xs-12">
    <div class="news-item">
      <a href="#" class="news-img-link">
        <div style="width: 100%; height: 200px" class="news-item-img">
          <img
            style="width: 100%; object-fit: cover; height: 100% "
            class="img-responsive"
            v-lazyload
            :data-url="`${blog.headerImage}`"
            alt="blog image"
          />
        </div>
      </a>
      <div class="news-item-text">
        <a style="height: 40px" href="#"
          ><h3>{{ blog.title }}</h3></a
        >
        <div class="dates">
          <span class="date"
            >{{ blog.createdAtDate }} /
            <img
              class="w-30 share-icon"
              :src="require('../assets/images/icons/share-icon-green.webp')"
          /></span>
        </div>
        <div class="news-item-descr big-news">
          <p>{{ inner }}...</p>
        </div>
        <div class="news-item-bottom">
          <router-link :to="`/blog/${blog.slug}`" class="news-link"
            >Read more...</router-link
          >
          <div class="admin"></div>
        </div>
      </div>
    </div>
    <b-modal
      @shown="setWidth"
      hide-footer
      centered
      scrollable
      :ref="`share-modal${blog.id}`"
      :id="`share-modal${blog.id}`"
      title="Share"
    >
      <ul style="list-style: none; padding: 0px">
        <li
          class="pb-2 pt-2 d-flex justify-content-center"
          style="border-bottom: 1px solid grey"
        >
          <ShareNetwork
            network="facebook"
            :url="`https://vencasa.com/blog/${blog.slug}`"
            :title="blog.title"
            :description="inner"
            :quote="blog.title"
            hashtags="vencasa"
          >
            <i style="color: #548f4d" class="fab fah fa-lg fa-facebook"></i>
            Facebook
          </ShareNetwork>
        </li>
        <li
          class="pb-2 pt-2 d-flex justify-content-center"
          style="border-bottom: 1px solid grey"
        >
          <ShareNetwork
            network="twitter"
            :url="`https://vencasa.com/blog/${blog.slug}`"
            :title="blog.title"
            :description="inner"
            :quote="blog.title"
            hashtags="vencasa"
          >
            <i style="color: #548f4d" class="fab fah fa-lg fa-twitter"></i>
            Twitter
          </ShareNetwork>
        </li>
        <li
          class="pb-2 pt-2 d-flex justify-content-center"
          style="border-bottom: 1px solid grey"
        >
          <ShareNetwork
            network="linkedin"
            :url="`https://vencasa.com/blog/${blog.slug}`"
            :title="blog.title"
            :description="inner"
            :quote="blog.title"
            hashtags="vencasa"
          >
            <i style="color: #548f4d" class="fab fah fa-lg fa-linkedin-in"></i>
            LinkedIn
          </ShareNetwork>
        </li>
        <li
          class="pb-2 pt-2 d-flex justify-content-center"
          style="border-bottom: 1px solid grey"
        >
          <ShareNetwork
            network="email"
            :url="`https://vencasa.com/blog/${blog.slug}`"
            :title="blog.title"
            :description="inner"
            :quote="blog.title"
            hashtags="vencasa"
          >
            <i style="color: #548f4d" class="fas fa-lg fa-envelope"></i> Email
          </ShareNetwork>
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import Quill from "quill";

export default {
  data() {
    return {
      user: null,
      inner: "",
    };
  },
  methods: {
    setWidth() {
      document.querySelector(
        `#share-modal${this.blog.id}___BV_modal_content_`
      ).style.width = "300px";
    },
    toBlogDetails(event) {
      if (event.target.classList.contains("share-icon"))
        this.$refs[`share-modal${this.blog.id}`].show();
      else this.$router.push(`/blog/${this.blog.slug}`);
    },
  },
  mounted() {
    let article = document.createElement("article");
    let quill = new Quill(article, { readOny: true });
    quill.setContents(JSON.parse(this.blog.description));
    this.inner = quill.root.innerText.substr(0, 160);
  },
  props: ["blog"],
};
</script>

<style scoped src="@/assets/css/styles.css">
</style>

<style scoped>

div >>> p {
  margin: 0;
  white-space: break-spaces;
}

.w-30 {
  width: 15px;
  height: 15px;
}
</style>