import { render, staticRenderFns } from "./articles.vue?vue&type=template&id=39aa1363&scoped=true&"
import script from "./articles.vue?vue&type=script&lang=js&"
export * from "./articles.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/styles.css?vue&type=style&index=0&id=39aa1363&scoped=true&lang=css&"
import style1 from "./articles.vue?vue&type=style&index=1&id=39aa1363&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39aa1363",
  null
  
)

export default component.exports